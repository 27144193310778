const lozad = require('lozad');
let observer1;
let observer2;

console.log(123);

function support_format_webp() {
  var elem = document.createElement('canvas');

  if (!!(elem.getContext && elem.getContext('2d'))) {
    // was able or not to get WebP representation
    return elem.toDataURL('image/webp').indexOf('data:image/webp') == 0;
  } else {
    // very old browser like IE 8, canvas not supported
    return false;
  }
}

const lazy = () => {
  const supportsWebp = support_format_webp();
  if (!observer1) {
    observer1 = lozad('.lozad:not(.auto)', {
      rootMargin: '200px 200px 200px 200px',
    });
    observer2 = lozad('.lozad.auto', {
      rootMargin: '200px 200px 200px 200px',
      load: function (el) {
        let src = el.getAttribute('data-src');
        if (!src) {
          return;
        }
        if (src.includes('img.paperform.co')) {
          el.src = src;
          return null;
        }

        if (src.startsWith('/')) {
          src = 'https://paperform.co' + src;
        }

        let format = 'auto';

        if (supportsWebp) {
          format = 'webp';
        }
        const width = window.innerWidth;
        const requestedWidth = width > 700 ? 1800 : 900;
        const widthTag = el.className.includes('fullsize')
          ? ''
          : `,w_${requestedWidth}`;
        el.src = `https://img.paperform.co/fetch/f_${format}${widthTag}/${src}`;
      },
    });
  }

  observer1.observe();
  observer2.observe();
};
exports.onInitialClientRender = () => {
  document.documentElement.className =
    document.documentElement.className.replace('_404-hide', '');
  if (process.env.NODE_ENV === `production` && window.analytics) {
    //window.analytics.page();
  }
};

exports.onRouteUpdate = function ({ location }, { gaExclude }) {
  lazy();
  setTimeout(lazy, 0);
  setTimeout(lazy, 100);
  //? Bail on analytics if the location is excluded.
  if (gaExclude && gaExclude.includes(location.pathname)) {
    return;
  }
  const noAnalytics = location.href.includes('_noga=1');
  if (noAnalytics) {
    return;
  }
  if (process.env.NODE_ENV === `production` && window.analytics) {
    window._noGaAnalytics = true;

    window.analytics.page && window.analytics.page();
    window.Intercom && window.Intercom('update');

    /** Quora content view update**/
    if (window.qp) {
      window.qp('track', 'ViewContent');
    }
  }
};
