export const HELP_CDN_URL = 'd3gw2uv1ch7vdq.cloudfront.net';
export const HELP_URL = 'pf-help.netlify.app';
export default function getHelpFileURL(src) {
  if (src.startsWith('../../')) src = src.slice(5);
  if (src.startsWith('../')) src = src.slice(2);
  if (src.includes('d3gw2uv1ch7vdq.cloudfront.net')) return src;
  if (src.includes('pf-help.netlify.app'))
    return src.replace(HELP_URL, HELP_CDN_URL);
  if (src.startsWith('/')) return `https://${HELP_CDN_URL}${src}`;

  return '';
}
