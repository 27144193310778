import 'whatwg-fetch';

import React, { useEffect } from 'react';

import {
  Intercom,
  LiveChatLoaderProvider,
  useChat,
} from 'react-live-chat-loader';
import { Provider } from './src/context';
import optimize from './src/utils/optimizeRender';
import isForm404 from './src/utils/isForm404';
import CookieConsent, { getCookieConsentValue } from 'react-cookie-consent';
import { HELP_URL } from './src/utils/getHelpFileURL';

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

global._pf_variant = '';

global.checkCookieConsent = () => {
  const hasConsent = getCookieConsentValue();
  global.hasCookieConsent = hasConsent === 'true';
  return hasConsent === 'true';
};

global.checkCookieConsent();

function shutdownIntercom() {
  global.Intercom && global.Intercom('shutdown');
  global.Intercom = () => {};
  const style = document.createElement('style');
  style.id = 'disable-intercom';
  style.innerHTML = `
  [class*=intercom] {
    display: none !important;
  }
  `;
  document.head.appendChild(style);
}

export const onRouteUpdate = () => {
  optimize();
  if (window.location.hash && window.location.hash.startsWith('#chat')) {
    const message = window.location.hash.includes('=')
      ? decodeURIComponent(window.location.hash.replace('#chat=', ''))
      : '';
    openChat(message);
  }
};

function openChat(message) {
  global.loadChat().then(() => {
    window.Intercom('showNewMessage', message);
  });
}

const isTopFrame = () => global.self === global.top;
export const onInitialClientRender = () => {
  // Prevent click jacking on public site
  const noAnalytics = global.location.href.includes('_noga=1');
  global.noAnalytics = noAnalytics;
  const is404 = isForm404(global.location);

  if (is404) {
    shutdownIntercom();
    return;
  }
  if (!isTopFrame()) {
    if (
      !global.document.referrer.includes('paperform.co') &&
      !global.document.referrer.includes('://localhost') &&
      !global.document.referrer.includes(HELP_URL)
    ) {
      global.document.body.innerHTML = '<div></div>';
      // global.top.location = global.self.location;
    } else {
      global.embedded = true;
      shutdownIntercom();
      document.body.setAttribute('embedded', 1);
    }
  }
  optimize();
};

class SimpleStore extends React.Component {
  constructor() {
    super();

    this.state = {};
  }

  componentDidMount() {
    try {
      const state = JSON.parse(localStorage.siteAppState);
      if (state) {
        this.setState(state);
      }
    } catch (e) {}
    let search = global.location.search;
    if (search && search[0] === '?') {
      search = [search.slice(1)];
    } else {
      search = [];
    }
    if (document.referrer) {
      search.push(`referrer=${encodeURIComponent(document.referrer)}`);
    }
    if (global._pf_variant) {
      search.push(`variant=${encodeURIComponent(global._pf_variant)}`);
    }
    search.push(`lp=${encodeURIComponent(global.location.href)}`);

    fetch(`/api/v1/me?${search.join('&')}`)
      .then((res) => {
        try {
          return res.json();
        } catch (e) {
          return { logged_in: false };
        }
      })
      .then(
        (res) => {
          if (global.noAnalytics) return;
          setTimeout(
            () => {
              if (res.logged_in && global.analytics) {
                setTimeout(
                  () => {
                    if (global.embedded) shutdownIntercom();
                    if (!global.intercomSettings) {
                      global.intercomSettings = {
                        app_id: 's2s64ipo',
                      };
                    }
                    global.intercomSettings.user_id = res.id;
                    global.intercomSettings.email = res.email;
                    global.intercomSettings.name = res.name;
                    global.intercomSettings.user_hash = res.user_hash;

                    global.analytics.identify(res.id, {
                      user_hash: res.user_hash,
                      name: res.name,
                      email: res.email,
                    });
                  },
                  global.Intercom ? 0 : 1000,
                );
              }
              this.setAppState({
                logged_in: !!res.logged_in,
                embedded_only: res.embedded_only,
                rates: res.rates,
              });
            },
            global.analytics ? 0 : 1000,
          );
        },
        () => {},
      );
  }

  setAppState = (args, cb) => {
    const state = { ...this.state, ...args };
    this.setState(state);
    global.appState = state;
    localStorage.siteAppState = JSON.stringify(state);
  };

  render() {
    return (
      <Provider value={{ appState: this.state, setAppState: this.setAppState }}>
        {this.props.children}
      </Provider>
    );
  }
}

function ExposeChat() {
  const [state, loadChat] = useChat();
  useEffect(() => {
    document.body.addEventListener(
      'click',
      function (e) {
        if (
          e.target.href?.includes('#chat') ||
          e.target.parentElement?.href?.includes('#chat')
        ) {
          const url = new URL(e.target.href || e.target.parentElement.href);
          const hash = url.hash;
          const message = hash.includes('=')
            ? decodeURIComponent(hash.replace('#chat=', ''))
            : '';
          openChat(message);
          e.preventDefault();
        }
      },
      true,
    );
  }, []);
  if (loadChat) {
    global.loadChat = () => {
      loadChat({ open: false });

      const checkIntercom = () => {
        return new Promise((resolve) => {
          if (!window.Intercom) {
            setTimeout(() => checkIntercom().then(resolve), 100);
          } else {
            resolve();
          }
        });
      };
      return checkIntercom();
    };
  } else {
    global.loadChat = () => Promise.resolve();
  }
  return null;
}
// You can delete this file if you're not using it
// module.exports ={};

export const wrapRootElement = ({ element }) => (
  <LiveChatLoaderProvider
    idlePeriod={1000}
    providerKey="s2s64ipo"
    provider="intercom"
  >
    <ExposeChat />
    <SimpleStore>{element}</SimpleStore>
    {!isTopFrame() ? null : <Intercom color="var(--midnight)" />}
    {!isTopFrame() ? null : (
      <CookieConsent
        style={{
          backgroundColor: 'rgba(255,255,255,.8)',
          backdropFilter: 'blur(10px)',
          color: 'var(--midnight)',
          fontSize: 14,

          paddingRight: 80,
        }}
        buttonStyle={{
          background: 'var(--yellow)',
          borderRadius: '17px',

          padding: '3px 16px',
        }}
        declineButtonStyle={{
          background: 'var(--salmon)',
          color: 'white',
          borderRadius: '17px',
          marginRight: 0,
          padding: '3px 16px',
        }}
      >
        This website uses cookies to ensure you get the best experience on our
        website.{' '}
        <a
          href="https://paperform.co/privacy/#cookies"
          style={{ color: 'black', textDecoration: 'underline' }}
          target="_blank"
          rel="nofollow noreferrer"
        >
          Learn more
        </a>
      </CookieConsent>
    )}
  </LiveChatLoaderProvider>
);
