module.exports = [{
      plugin: require('../plugins/gatsby-source-paperform/gatsby-browser.js'),
      options: {"plugins":[],"apiUrl":"https://paperform.co","apiKey":"LDB5hnYnFj2ftlRQiLtxTjlnKwf2fn","gaExclude":["/inapp-help","/inapp-help/"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-LRJPP419ST"],"gtagConfig":{"optimize_id":"GTM-MDR9HLP","anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":true,"exclude":["/inapp-help","/inapp-help/","**/*_noga=1**"],"respectDNT":false,"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
